// Display Meals

// Importieren der Module
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./match.css";
import CircularIndeterminate from "../../components/loadingcircle/loadingcircle";
import Card from "../../components/card/card";
import Navigator from "../../components/namiswan/navigator";
import Menu from "../../components/menu/menupink";
import Overlay from "../../components/overlay/overlay";

function CardStack() {
  // Caching
  const strapiUrl = "https://strapi.matchmeal.de";
  const endpointUrl = `${strapiUrl}/api/meals?populate=*`;
  const [cards, setCards] = useState([]);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [trash] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [finalFavourites, setFinalFavourites] = useState([]);

  let data = useEffect(() => {
    axios
      .get(endpointUrl)
      .then((response) => {
        let sortedArray = shuffleArray(response.data.data);
        setCards(sortedArray);
        setCardsLoaded(true);
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Daten:", error);
      });
  }, []);

  // Zufällige Anordnung des Arrays der Gerichte
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  // Removed geswipede "card" aus dem "[cards]" Array
  function removeCard(cardId) {
    const updatedCardStack = cards.filter((card) => card.id !== cardId);
    setCards(updatedCardStack);
    console.log("alle Cards", cards);
  }

  // Fügt geswipede Card zum "[trash]" Array hinzu
  const pushToTrash = (card) => {
    trash.push(card);
    console.log("trash", trash);
    removeCard(card.id);
  };

  // Fügt geswipede Card zum "[finalFavourites]" Array hinzu
  const pushToFinalFavourites = (card) => {
    finalFavourites.push(card);
    setFinalFavourites(finalFavourites);
    localStorage.setItem("finalFavourites", JSON.stringify(finalFavourites));
    removeCard(card.id);
    console.log("finalFavorites", finalFavourites);
  };

  // Fügt geswipede Card zum "[favourites]" Array hinzu
  const pushToFavourites = (card) => {
    favourites.push(card);
    setFavourites(favourites);
    localStorage.setItem("favourites", JSON.stringify(favourites));
    removeCard(card.id);
    console.log("favoriten", favourites);
  };

  const InfoOverlay = ({ isOpen, onClose, children }) => {
    return (
      <>
        {isOpen ? (
          <div className="overlay">
            <div className="overlay__background" onClick={onClose}>
              <div className="overlay__container">
                <div className="overlay__controls">
                  <button
                    className="overlay__close"
                    type="button"
                    onClick={onClose}
                  ></button>
                </div>
                {children}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  if (favourites.length < 5 && cards.length !== 0) {
    console.log("Länge", cards.length);

    return (
      <div>
        <div>
          <Overlay />
        </div>
        <div className="menu">
          <Menu />
        </div>

        <div style={{}}>
          {cards.map((card) => (
            <Card
              strapiUrl={strapiUrl}
              key={card.id}
              card={card}
              functions={{
                pushToFavourites,
                pushToFinalFavourites,
                pushToTrash,
                InfoOverlay,
              }}
              data={data}
            />
          ))}
        </div>
      </div>
    );
  } else if (!cardsLoaded) {
    return <CircularIndeterminate />;
  } else if (cards.length === 0 && favourites.length < 1) {
    return (
      <Navigator
        text="Dir hat davon nichts gefallen? Starte die Suche erneut!"
        route={"/"}
      />
    );
  } else if (cards.length === 0 && favourites.length === 1) {
    return (
      <Navigator
        text={`${favourites.length} heißes Meal wartet auf Dich!`}
        route={"/matched"}
      />
    );
  } else {
    return (
      <Navigator
        text={`${favourites.length} heiße Meals warten auf Dich! Entscheide dich für deine 2 Favoriten`}
        route={"/matched"}
      />
    );
  }
}

export default CardStack;
