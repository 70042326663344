// Button Komponente
import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Styling
const ColorButton = styled(Button)(() => ({
  margin: 15,
  height: 60,
  width: 160,
  borderRadius: 10,
  backgroundColor: "#272a33",
  boxShadow: "none",
  fontFamily: "poppins",
  fontweight: "medium",
  fontSize: 23,
  textTransform: "capitalize",
  paddingBottom: 9,
  paddingRight: 20,
  "&:hover": {
    backgroundColor: "#272a33",
    boxShadow: "none",
    paddingBottom: 6,
    paddingRight: 17,

    "&::before": {
      top: 0,
      left: 0,
      transition: "ease",
    },
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: -3,
    left: -3,
    width: 157,
    height: 57,
    border: "1.5px solid #636d7f",
    borderRadius: 10,
    opacity: 1,
  },
}));

const PinkText = styled(Typography)(() => ({}));

const sxMeStyle = {
  color: "#e10064",
  fontStyle: "italic",
  fontFamily: "poppins",
  fontWeight: "medium",
  fontSize: 23,
};

// Button generieren
export default function CustomizedButtons() {
  return (
    <ColorButton variant="contained">
      Match&nbsp;
      <PinkText sx={sxMeStyle}>Me</PinkText>
    </ColorButton>
  );
}
