// Slider

// importieren der Module
import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Bowl from "./icons/bowl.webp";
import Burger from "./icons/burger.webp";
import Secret from "./icons/secret.webp";
import Hype from "./icons/hype.webp";
import Jogger from "./icons/jogger.webp";
import Bowtie from "./icons/bowtie.webp";
// import "./slider.css";

// Styling
const sxStyleBox = {
  width: "100%",
  display: "flex",
  px: 1,
  pt: 1.25,
  marginBottom: "22.5px",
  alignItems: "center",
};

const sxStyleSlider = {
  height: 14,
  padding: "",
  mx: 5,
  color: "#e10064",
  alignItems: "center",
  position: "relative",
  "&::before": {
    content: '""',
    display: "flex",
    justifyContent: "center",
    alignContnet: "center",
    backgroundColor: "#272a33",
    width: "370px",
    // paddingLeft: 5,
    // paddingRight: 5,
    position: "absolute",
    height: 75,
    borderRadius: 100,
    // borderTopLeftRadius: 100,
    // borderBottomLeftRadius: 100,
    // position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",

    // transform: "translateX(10%)", // Horizontal zentriert
  },
  "& .MuiSlider-rail": {
    color: "#636D7F",
    opacity: 1,
    height: 12,
  },
  "& .MuiSlider-thumb": {
    width: "25px",
    height: "25px",
  },
  "& .MuiSlider-track": {
    height: 12,
  },
};

const sxStyleSliderIcon = {
  width: "auto", // Breite des Bildes
  height: "60px", // Höhe des Bildes
  zIndex: 2,
};

function valuetext(value) {
  return `${value}`;
}
// Slider erstellen
export default function RangeSlider(props) {
  const [value, setValue] = React.useState([0, 10]);

  const handleChange = (event, newValue) => {
    if (newValue[1] - newValue[0] >= 3) {
      setValue(newValue);

      props.onUpdateState(value);
    }
  };

  // Alle Icons einfügen
  const Icons = {
    bowl: Bowl,
    burger: Burger,
    secret: Secret,
    hype: Hype,
    jogger: Jogger,
    bowtie: Bowtie,
  };
// rendern der Slider
  return (
    <Box sx={sxStyleBox}>
      {Object.keys(Icons).map((iconName) => {
        if (iconName == props.leftIcon) {
          return (
            <img
              src={Icons[iconName]}
              alt="SliderIcon"
              style={sxStyleSliderIcon}
            />
          );
        }
      })}

      <Slider
        sx={sxStyleSlider}
        getAriaLabel={() => "Search customisation"}
        value={value}
        min={0}
        max={10}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
      {Object.keys(Icons).map((iconName) => {
        if (iconName === props.rightIcon) {
          return (
            <img
              src={Icons[iconName]}
              alt="SliderIcon"
              style={sxStyleSliderIcon}
            />
          );
        }
      })}
    </Box>
  );
}
