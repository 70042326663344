// Display der Gerichte

// Importieren der Module
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
// import WebFont from "webfontloader";
import Overlay from "../overlay/overlay";
import "../../fonts/fonts.css";

Modal.setAppElement("#root"); // Hier '#root' sollte das Wurzelelement Ihrer Anwendung sein

// WebFont.load({
//   google: {
//     families: ["Poppins: @100,200,300,400,500,600,700,800,900"],
//   },
// });

// Styling
const sxStyleInfos = {
  width: "390px",
  color: "white",
  fontFamily: "poppins",
  marginTop: "0px",
  backgroundColor: "rgba(39, 42, 51, 1",
  borderRadius: "0px 0px 10px 10px",
  paddingTop: "70px",
  paddingBottom: "30px",
  paddingLeft: "30px",
  marginBottom: "5px",
  position: "absolute",
  zIndex: 3,
};

const sxStyleCloseBtn = {
  fontFamily: "poppins",
  fontSize: "16px",
  color: "#e10064",
  backgroundColor: "transparent",
  border: "none",
  marginTop: "32px",
  marginLeft: "30px",
  position: "fixed",
  zIndex: 4,
  cursor: "pointer",
};

const sxStyleRestart = {
  overlay: {
    background: "transparent",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    // border: "2px solid red",
    marginLeft: 0,
  },
  content: {
    color: "white",
    fontFamily: "poppins",
    fontWeight: 400,
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: "#272A33",
    border: 0,
    borderRadius: "10px",
    width: "310px",
    height: "300px",
    margin: "auto",
    marginBottom: "-50px",
    paddingTop: 30,
    // transform: "translateX(-10%)",
    position: "absolute",
  },
};

const sxStyleRestartBtn = {
  fontWeight: 400,
  color: "#e10064",
  textDecoration: "",
};

const sxStyleContinueBtn = {
  fontFamily: "poppins",
  fontSize: "16px",
  fontWeight: 400,
  color: "#ffffff",
  backgroundColor: "transparent",
  border: "none",
  marginTop: "12px",
  zIndex: 4,
  cursor: "pointer",
  textDecoration: "underline",
};

// Card Komponente erstellen
function Card(props) {
  // const card = props.card;
  const URL = props.strapiUrl;

  let startX, currentX;
  let startY, currentY;
  const [favourites, setFavourites] = useState([]);
  const [finalFavourites] = useState([]);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setFavourites(JSON.parse(localStorage.getItem("favourites")));
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleRestart = () => {
    setIsOpen(!isOpen);
  };

  const renderRestart = () => {
    if (isOpen) {
      return (
        <div>
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Auswahl-Popup"
            style={sxStyleRestart}
          >
            <h2 style={{ marginBottom: "12px" }}>
              Möchtest du die Suche wirklich neustarten?
            </h2>

            <Link to="/" style={sxStyleRestartBtn}>
              Ja, ich möchte was anderes
            </Link>

            <button
              type="button"
              onClick={closeModal}
              style={sxStyleContinueBtn}
            >
              Nein, ich möchte weitermachen
            </button>
          </Modal>
        </div>
      );
    }
    return null;
  };

  const toggleOverlay = () => {
    setIsOverlayOpen(!isOverlayOpen);
  };

  const renderOverlay = () => {
    if (isOverlayOpen) {
      return (
        <div className="overlay">
          <div className="overlay__background">
            <div className="overlay__container">
              <div className="overlay__controls">
                <button
                  className="overlay__close"
                  type="button"
                  onClick={toggleOverlay}
                  style={sxStyleCloseBtn}
                >
                  Close
                </button>
              </div>
              <div style={sxStyleInfos}>
                <h2
                  style={{
                    marginBottom: "12px",
                    marginTop: "10px",
                    fontWeight: 700,
                  }}
                >{` ${props.card.attributes.name}`}</h2>

                <h2
                  style={{ marginBottom: "12px", fontWeight: 400 }}
                >{` ${props.card.attributes.restaurant.name}`}</h2>

                <div
                  style={{
                    display: "flex",

                    marginRight: "37.5px",
                    justifyContent: "space-between",
                  }}
                >
                  <h3
                    style={{ fontWeight: 400 }}
                  >{` ${props.card.attributes.price}€`}</h3>
                  <h3
                    style={{ fontWeight: 400 }}
                  >{` ${props.card.attributes.mealtype[0].name}`}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  // Swipe Funktion
  const swipe = (e) => {
    let x;
    let y;
    if (e.touches) {
      x = e.touches[0].clientX;
      y = e.touches[0].clientY;
    } else {
      x = e.clientX;
      y = e.clientY;
    }
    const deltaX = x - startX;
    const deltaY = y - startY;

    currentX = deltaX;
    currentY = deltaY;
  };
  // Startpunkt der Swipe Funktion erfassen
  const startSwipe = (e) => {
    startX = e.clientX || e.touches[0].clientX;
    startY = e.clientY || e.touches[0].clientY;
    console.log("Start");
  };

  // Endpunkt der Swipe Funktion erfassen & Deklaration was mit dem geswipeden Meal passieren soll

  const endSwipe = (e) => {
    console.log("End");
    if (currentX > 100) {
      // Swipe rechts = Ja
      setTimeout(() => {}, 200);

      if (favourites < 5) {
        props.functions.pushToFavourites(props.card);
      } else if (finalFavourites < 2) {
        props.functions.pushToFinalFavourites(props.card);
      } else {
        props.functions.pushToDate(props.card);
      }
    } else if (currentX < -100) {
      // Swipe links = Nein
      setTimeout(() => {}, 200);
      props.functions.pushToTrash(props.card);
    } else if (currentY < -25) {
      // Swipe Up = Restart
      toggleRestart();
    } else if (currentY > 25) {
      // Swipe Down = Infos
      toggleOverlay();

      setTimeout(() => {}, 200);
    }
  };
  // Generieren der Images
  return (
    <div style={{ objectFit: "cover", width: "390px", height: "100vh" }}>
      {renderRestart()}
      {renderOverlay()}
      <img
        className="card"
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
        draggable="false"
        src={URL + props.card.attributes.image.data[0].attributes.url}
        alt={"Meal"}
        onMouseDown={startSwipe}
        onMouseMove={swipe}
        onMouseUp={endSwipe}
        onTouchStart={startSwipe}
        onTouchMove={swipe}
        onTouchEnd={endSwipe}
      ></img>
    </div>
  );
}
// Card Komponente exportieren
export default Card;





