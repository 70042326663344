// Menü für die Startseite

// importieren der Module
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {MenuItem, Link} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./menu.css";
// import { Link } from "react-router-dom";

export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sxMenuText = {
    color: "white",
    textAlign: "center",

    "&:hover": {
      color: "#e10064",
    },
    justifyContent: "center",
  };

  const sxLinkStyle = {
    color: "white",
    fontSize: 16,
    textDecoration: "none",
    textAlign: "center",
    margin: "auto",

    "&:hover": {
      color: "#e10064 !important",
    },
  };

  const sxStyleMenu = {
    "& .MuiList-root": {
      backgroundColor: "#272a32",
      width: "340px",
      // position: "relative",
    },
    "& .MuiPaper-elevation": {
      display: "flex",
      marginTop: "60px",
      marginLeft: "-8px",
      // marginLeft: "60px",
      position: "absolute",
      borderRadius: "8px",
    },
  };
  // rendern des Menüs

  return (
    <div style={{ width: "35px", marginRight: "22.5px", position: "relative" }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="icon-large"
      >
        <MenuIcon id="menuIcon" style={{ fontSize: "35px", color: "white" }} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={sxStyleMenu}
      >
        <div>
          <MenuItem sx={sxMenuText} onClick={handleClose}>
            <Link href="/" sx={sxLinkStyle}>
              Home
            </Link>
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            Profile
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            About Us
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            Kontakt
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            <Link href="/datenschutz" sx={sxLinkStyle}>
              Datenschutz
            </Link>
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            <Link href="/impressum" sx={sxLinkStyle}>
              Impressum
            </Link>
          </MenuItem>
          <MenuItem  sx={sxMenuText} onClick={handleClose}>
            Einstellungen
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
}
