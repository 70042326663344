// 3. screen - display saved matches

// Importieren der Module
import React, { useState, useEffect } from "react";
import CircularIndeterminate from "../../components/loadingcircle/loadingcircle";
import Card from "../../components/card/card";
import Navigator from "../../components/namiswan/navigator";
import Menu from "../../components/menu/menupink";
import SecondOverlay from "../../components/overlay/secondOverlay";

function FinalCardStack() {
  // Caching
  const [favourites, setFavourites] = useState([]);
  const strapiUrl = "https://strapi.matchmeal.de";
  const [cards, setCards] = useState([]);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [trash] = useState([]);
  const [finalFavourites, setFinalFavourites] = useState([]);

  useEffect(() => {
    setFavourites(JSON.parse(localStorage.getItem("favourites")));
    setCardsLoaded(true);
  }, []);

  // Removed geswipede "card" aus dem "[cards]" Array
  function removeCard(cardId) {
    const updatedCardStack = cards.filter((card) => card.id !== cardId);
    setCards(updatedCardStack);
    console.log("alle Cards", cards);
  }
  function removeCardFavourites(cardId) {
    const updatedCardStack = favourites.filter((card) => card.id !== cardId);
    setFavourites(updatedCardStack);
    console.log("alle favs", favourites);
  }
  function removeCardTrash(cardId) {
    const updatedCardStack = favourites.filter((card) => card.id !== cardId);
    setFavourites(updatedCardStack);
    console.log("alle favs", favourites);
  }

  // Fügt geswipede Card zum "[trash]" Array hinzu
  const pushToTrash = (card) => {
    trash.push(card);
    console.log("trash", trash);
    removeCardTrash(card.id);
  };

  const pushToFavourites = (card) => {
    favourites.push(card);
    setFavourites(favourites);
    localStorage.setItem("favourites", JSON.stringify(favourites));
    removeCard(card.id);
    console.log("favoriten", favourites);
  };

  // Fügt geswipede Card zum "[favourites]" Array hinzu
  const pushToFinalFavourites = (card) => {
    finalFavourites.push(card);
    setFinalFavourites(finalFavourites);
    localStorage.setItem("finalFavourites", JSON.stringify(finalFavourites));
    removeCardFavourites(card.id);

    console.log("finalFavorites", finalFavourites);
  };

  const InfoOverlay = ({ isOpen, onClose, children }) => {
    return (
      <>
        {isOpen ? (
          <div className="overlay">
            <div className="overlay__background" onClick={onClose}>
              <div className="overlay__container">
                <div className="overlay__controls">
                  <button
                    className="overlay__close"
                    type="button"
                    onClick={onClose}
                  ></button>
                </div>
                {children}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  if (finalFavourites.length < 2 && favourites.length !== 0) {
    console.log("Länge der Finalisten:", cards.length);

    return (
      <div>
        <div className="menu">
          <Menu />
        </div>
        {favourites.map((card) => (
          <Card
            strapiUrl={strapiUrl}
            key={card.id}
            card={card}
            functions={{
              pushToFinalFavourites,
              pushToFavourites,
              pushToTrash,
              InfoOverlay,
            }}
            // data={data}
          />
        ))}
      </div>
    );
  } else if (!cardsLoaded) {
    return <CircularIndeterminate />;
  } else if (favourites.length === 0 && finalFavourites.length < 1) {
    return (
      <Navigator
        text="Dir hat davon doch nichts gefallen? Starte die Suche erneut!"
        route={"/"}
      />
    );
  } else if (favourites.length === 0 && finalFavourites.length === 1) {
    return (
      <Navigator
        text={`${finalFavourites.length} heißes Meal wartet auf Dich!`}
        route={"/decision"}
      />
    );
  } else {
    return (
      <Navigator
        text={`${finalFavourites.length} heiße Meals warten auf Dich! Entscheide dich jetzt!`}
        route={"/decision"}
      />
    );
  }
}

export default FinalCardStack;
