// Router

// importieren der Module
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Meals from "./screens/matchscreen/match";
import Start from "./screens/startscreen/start";
import Matched from "./screens/matchedscreen/matched";
import Decision from "./screens/decisionscreen/decision";
import Date from "./screens/datescreen/datescreen";
import Datenschutz from "./rechtliches/datenschutz";
import Impressum from "./rechtliches/impressum";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <Router>
      <Routes>
        <Route path="/" Component={Start} />
        <Route path="/matchme" Component={Meals} />
        <Route path="/matched" Component={Matched} />
        <Route path="/decision" Component={Decision} />
        <Route path="/date" Component={Date} />
        <Route path="/datenschutz" Component={Datenschutz} />
        <Route path="/impressum" Component={Impressum} />
      </Routes>
    </Router>
  </div>
);
