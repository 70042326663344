// letzter Screen - Gericht mit Infos zum Restaurant anzeigen

// importieren der Module
import React, { useState, useEffect } from "react";
import "../matchscreen/match.css";
import CircularIndeterminate from "../../components/loadingcircle/loadingcircle";
import Card from "../../components/card/dateCard";
import Menu from "../../components/menu/menupink";


function Date() {
  // Caching
  const strapiUrl = "https://strapi.matchmeal.de";
  const [cardsLoaded] = useState(false);
  const [date, setDate] = useState([]);

  useEffect(() => {
    setDate(JSON.parse(localStorage.getItem("date")));
  }, []);
// nach links geswipede Meals im Array Trash Array speichern
    const pushToTrash = (card) => {
    };
// nach rechts geswipede Meals im local Storage speichern
      const pushToFinalFavourites = (card) => {
    localStorage.setItem("date", JSON.stringify(date));}
// Overlay für Informationen
  const InfoOverlay = ({ isOpen, onClose, children }) => {
    return (
      <>
        {isOpen ? (
          <div className="overlay">
            <div className="overlay__background" onClick={onClose}>
              <div className="overlay__container">
                <div className="overlay__controls">
                  <button
                    className="overlay__close"
                    type="button"
                    onClick={onClose}
                  ></button>
                </div>
                {children}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  // rendern 
  if (date.length === 1) {
    return (
      <div>
        <div className="menu">
          <Menu />
        </div>
        {date.map((card) => (
          <Card
            strapiUrl={strapiUrl}
            key={card.id}
            card={card}
            functions={{
              InfoOverlay,
              pushToFinalFavourites,
              pushToTrash
            }}
           
          />
        ))}
      </div>
    );
  } else if (!cardsLoaded) {
    return <CircularIndeterminate />;
  } else {
    return null;
  }
}

export default Date
