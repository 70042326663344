import React from "react";
// import WebFont from "webfontloader";
import "../fonts/fonts.css"
import Menu from "../../src/components/menu/menu";

// WebFont.load({
//   google: {
//     families: ["Poppins: @100,200,300,400,500,600,700,800,900"],
//   },
// });

const sxlinkStyle = {
  color: "#e10064",
  textDecoration: "none",
};

const Datenschutz = () => {
  return (
    <div
      style={{
        color: "white",
        // fontFamily: "poppins",
        fontFamily: "poppins",
        background: "#272a33",
        padding: 0,
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "100vh",
        maxWidth: "390px",
      }}
    >
      <div className="menu" >
        <Menu />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div style ={{padding: 20}}>
      <h2>Datenschutzerklärung</h2>
      <br></br>
      <br></br>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <br></br>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <br></br>
      <br></br>
      <h3>Datenerfassung auf dieser Website</h3>
      <br></br>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <br></br>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt "Hinweis
        zur Verantwortlichen Stelle" in dieser Datenschutzerklärung entnehmen.
      </p>
      <br></br>
      <br></br>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        <br></br>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </p>
      <br></br>
      <br></br>
      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <br></br>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <br></br>
      <br></br>
      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <br></br>
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden.
      </p>
      <br></br>
      <br></br>
      <br></br>
      <h2>2. Hosting</h2>
      <br></br>
      <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
      <br></br>
      <br></br>
      <h3>Alfahosting</h3>
      <br></br>
      <p>
        Anbieter ist die Alfahosting GmbH, Ankerstraße 3b, 06108 Halle (Saale)
        (nachfolgend Alfahosting) Wenn Sie unsere Website besuchen, erfasst
        Alfahosting verschiedene Logfiles inklusive Ihrer IP-Adressen.
      </p>
      <p>
        Details entnehmen Sie der Datenschutzerklärung von Alfahosting:{" "}
        <a
          href="https://alfahosting.de/datenschutz/"
          style={sxlinkStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          https://alfahosting.de/datenschutz/
        </a>
      </p>
      <p>
        Die Verwendung von Alfahosting erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst
        zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. für Device-Fingerprinting)
        im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <br></br>
      <br></br>
      <br></br>
      <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
      <br></br>
      <br></br>
      <h3>Datenschutz</h3>
      <br></br>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <br></br>
      <br></br>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <br></br>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Matrixcode Academy GmbH
        <br />
        Falkenried 88 (Haus E)
        <br />
        20251 Hamburg
      </p>
      <p>
        Telefon: +49 175 10 50 586
        <br />
        E-Mail: info@matrixcode.academy
      </p>
      <br></br>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <br></br>
      <br></br>
      <h3>Speicherdauer</h3>
      <br></br>
      <p>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letzteren Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </p>
      <br></br>
      <br></br>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <br></br>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <br></br>
      <br></br>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <br></br>
      <p>
        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f
        DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus
        Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
        personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein
        auf diese Bestimmungen gestütztes Profiling. Die jeweilige
        Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
        dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
        Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei
        denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder
        die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
      </p>
      <br></br>
      <p>
        Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
        betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
        Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
        es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
        widersprechen, werden Ihre personenbezogenen Daten anschließend nicht
        mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
        Abs. 2 DSGVO).
      </p>
      <br></br>
      <br></br>
      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <br></br>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <br></br>
      <br></br>
      <h3>Recht auf Datenübertragbarkeit</h3>
      <br></br>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <br></br>
      <br></br>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <br></br>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von "http://" auf "https://" wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <br></br>
      <br></br>
      <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>
      <br></br>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit an uns wenden.
      </p>
      <br></br>
      <br></br>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <br></br>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
      </p>
      <br></br>
      <br></br>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <br></br>

        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </li>
        <br></br>

        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <br></br>

        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht festste.ht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
        <br></br>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <br></br>
      <br></br>
      <h3>Widerspruch gegen Werbe-Mails</h3>
      <br></br>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <br></br>
      <br></br>
      <br></br>
      <h2>3. Datenerfassung auf unserer Website</h2>
      <br></br>
      <br></br>
      <h3>Cookies</h3>
      <br></br>
      <p>
        Unsere Internetseiten verwenden so genannte "Cookies". Cookies sind
        kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <br></br>
      <p>
        Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen
        stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die
        Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb
        von Webseiten (z. B. Cookies zur Abwicklung von
        Zahlungsdienstleistungen).
      </p>
      <br></br>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
        funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
        Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder
        zu Werbezwecken verwendet werden.
      </p>
      <br></br>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs,
        zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
        für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
        Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
        Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
        notwendigen Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
        von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <br></br>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <br></br>
      <p>
        Welche Cookies und Dienste auf dieser Website eingesetzt werden, können
        Sie dieser Datenschutzerklärung entnehmen.
      </p>
      <br></br>
      <br></br>
      <h3>Server-Log-Dateien</h3>
      <br></br>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <br></br>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <br></br>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
      </p>
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        technisch fehlerfreien Darstellung und der Optimierung seiner Website –
        hierzu müssen die Server-Log-Files erfasst werden.
      </p>
      <br></br>
      <br></br>
      <br></br>
      <h2>5. Plugins und Tools</h2>
      <br></br>
      <br></br>
      <h3>Google Fonts (lokales Hosting)</h3>
      <br></br>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Google Fonts, die von Google bereitgestellt werden. Die Google
        Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
        findet dabei nicht statt.
      </p>
      <p>
        Weitere Informationen zu Google Fonts finden Sie unter{" "}
        <a
          href="https://developers.google.com/fonts/faq"
          style={sxlinkStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          https://developers.google.com/fonts/faq
        </a>{" "}
        und in der Datenschutzerklärung von Google:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          style={sxlinkStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
      </p>
      <p>
        Quelle:{" "}
        <a href="https://www.e-recht24.de" style={sxlinkStyle}>
          https://www.e-recht24.de
        </a>
      </p>
      </div>
    </div>
  );
};
export default Datenschutz;
