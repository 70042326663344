// Startseite

// importieren der Module
import React, { useState } from "react";
import RangeSlider from "../../components/slider/slider";
import CustomizedCheckbox from "../../components/checkbox/checkbox";
import Button from "../../components/button/button";
import Header from "../../components/header/header";
import Container from "@mui/material/Container";
import "../../fonts/fonts.css";
import { Link } from "react-router-dom";

// import WebFont from "webfontloader";

// WebFont.load({
//   google: {
//     families: ["Poppins"],
//   },
// });

const Start = () => {
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [sliderValueHealth, setSliderValueHealth] = useState();
  const [sliderValueHype, setSliderValueHype] = useState();
  const [sliderValueOutfit, setSliderValueOutfit] = useState();

  const updateHealthSlider = (data) => {
    if (!checkboxChecked) {
      setSliderValueHealth(data);
      console.log("Health", sliderValueHealth);
    }
    setCheckboxChecked(false);
  };

  const updateHypeSlider = (data) => {
    if (!checkboxChecked) {
      setSliderValueHype(data);
      console.log("Hype", sliderValueHype);
    }
    setCheckboxChecked(false);
  };

  const updateOutfitSlider = (data) => {
    if (!checkboxChecked) {
      setSliderValueOutfit(data);
      console.log("Outfit", sliderValueOutfit);
    }
    setCheckboxChecked(false);
  };

  const toggleCheckbox = (newState) => {
    // if (newState) {
    //   setSliderValueHealth([0, 10]);
    //   setSliderValueHype([0, 10]);
    //   setSliderValueOutfit([0, 10]);
    // }
    setCheckboxChecked(newState);
    console.log(sliderValueHealth, sliderValueHype, sliderValueOutfit);
  };

  const iconNameStyle = {
    fontWeight: "normal",
    fontSize: "14px",
  };

  const TextOneStyle = {
    display: "flex",
    fontFamily: "Poppins",
    color: "white",
    fontWeight: "normal",
    fontSize: "18px",
    textAlign: "center",
    margin: "auto",
    padding: "auto",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#272a33",
    width: "260px",
    height: "80px",
    borderRadius: 10,
  };

  const TextTwoStyle = {
    display: "flex",
    fontFamily: "poppins",
    color: "white",
    fontWeight: "normal",
    fontSize: "14px",
    marginLeft: "20px",
    padding: "auto",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#272a33",
    width: "260px",
    height: "80px",
    borderRadius: 10,
  };

  const TextContainerOneStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#272a33",
    width: "260px",
    height: "80px",
    borderRadius: "10px",
    position: "relative",
    margin: "auto",
    marginBottom: "20px",
  };

  const TextContainerTwoStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#272a33",
    maxWidth: "250px",
    height: "95px",
    borderRadius: "10px",
    position: "relative",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "20px",
  };

  const clearFavourites = () => {
    console.warn("clearStorage");
    localStorage.clear();
  };

  return (
    <div style={{ maxWidth: "390px" }}>
      <Container
        style={{
          overflowX: "hidden",
          overflowY: "scroll",
          maxWidth: "390px",
          maxHeight: "100vh",
          marginTop: "-20px",
        }}
      >
        <Header />

        <div style={TextContainerOneStyle}>
          <h3 style={TextOneStyle}>
            Konfiguriere MatchMeal <br></br>nach deinen Wünschen
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "-22px",
            marginRight: "-22px",
          }}
        >
          <div style={TextContainerTwoStyle}>
            <h3 style={TextTwoStyle}>
              Passe deine Vorlieben für dein optimales Suchergebniss an{" "}
              <br></br> oder lass dir alles anzeigen!
            </h3>
          </div>
          <CustomizedCheckbox
            checkboxChecked={checkboxChecked}
            onToggle={toggleCheckbox}
          />
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontFamily: "poppins",
              color: "white",
              marginLeft: "19px",
              marginRight: "19.5px",
            }}
          >
            <h2 style={iconNameStyle}>Leicht</h2>
            <h2 style={iconNameStyle}>Schwer</h2>
          </div>

          <RangeSlider
            onUpdateState={updateHealthSlider}
            leftIcon={"bowl"}
            rightIcon={"burger"}
            value={sliderValueHealth}
          />
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontFamily: "poppins",
              color: "white",
              marginLeft: "11px",
              marginRight: "26px",
            }}
          >
            <h2 style={iconNameStyle}>Geheim</h2>
            <h2 style={iconNameStyle}>Hype</h2>
          </div>

          <RangeSlider
            onUpdateState={updateHypeSlider}
            leftIcon={"secret"}
            rightIcon={"hype"}
            value={sliderValueHype}
          />
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontFamily: "poppins",
              color: "white",
              marginLeft: "17px",
              marginRight: "22px",
            }}
          >
            <h2 style={iconNameStyle}>Locker</h2>
            <h2 style={iconNameStyle}>Schick</h2>
          </div>

          <RangeSlider
            onUpdateState={updateOutfitSlider}
            leftIcon={"jogger"}
            rightIcon={"bowtie"}
            value={sliderValueOutfit}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link onClick={clearFavourites} to="/matchme">
            <Button />
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Start;
