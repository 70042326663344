// Navigator

// importieren der Module
import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/button/button";
// import WebFont from "webfontloader";
import "../../fonts/fonts.css";


// font
// WebFont.load({
//   google: {
//     families: ["Poppins: @100,200,300,400,500,600,700,800,900"],
//   },
// });

// Styling
const sxStyleNavi = {
  display: "flex",
  width: "390px",
  height: "900px",
  color: "white",
  fontFamily: "poppins",
  fontWeight: 400,
  textAlign: "center",
  marginTop: "0px",
  backgroundColor: "rgba(39, 42, 51, 1",
  borderRadius: "0px 0px 10px 10px",
  paddingTop: "70px",
  paddingBottom: "30px",
  position: "absolute",
  padding: "35px",
  zIndex: 3,
  flexFlow: "column wrap",
};

function Navigator(props) {
  return (
    <div style={sxStyleNavi}>
      <h1 style={{ fontFamily: "poppins", fontWeight: 400, marginTop: "15px" }}>
        {props.text}
      </h1>
      <Link style={{ marginTop: "70px" }} to={props.route}>
        <Button />
      </Link>

      <Link style={{ marginTop: "12px", color: "white" }} to="/">
        Starte von vorne
      </Link>
    </div>
  );
}
export default Navigator;
