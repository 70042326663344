// header

// importieren der Module
import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Logo from "./logo_weiß.webp";
import Menu from "../menu/menu";
import Lines from "./lines_top.webp";

// Styling
const Header = styled("div")(() => ({
  display: "flex",
  marginTop: 40,
  marginBottom: 20,
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
}));

const WhiteText = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "poppins",
  color: "white",
  fontWeight: "medium",
}));

const PinkTextOne = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "poppins",
  color: "#e10064",
  fontWeight: "medium",
  fontStyle: "italic",
}));

const PinkTextTwo = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "poppins",
  color: "#e10064",
  fontWeight: "medium",
  marginRight: "auto",
}));

// generieren des Headers
export default function MyComponent() {
  return (
    <div>
      <Header>
        <img
          src={Logo}
          style={{
            height: "30px",
            marginRight: "auto",
            marginLeft: "10px",
          }}
          alt="logo"
          id="logo"
        />
        <WhiteText id="match">Match</WhiteText>
        <PinkTextOne id="me">Me</PinkTextOne>
        <PinkTextTwo id="al">al</PinkTextTwo>
        <Menu id="menu" style={{}} />
        <img
          src={Lines}
          style={{ height: "10px", marginTop: "1px" }}
          alt="LinesSvg"
          id="linesSvg"
        />
      </Header>
    </div>
  );
}
