import React, { useState } from "react";
// import WebFont from "webfontloader";

// WebFont.load({
//   google: {
//     families: ["Poppins"],
//   },
// });
import "../../fonts/fonts.css";


const sxStyleOverlay = {
  display: "flex",
  position: "relative",
  margin: "auto",
  justifyContent: "center",
  bottom: 165,
  right: 170,
};

const sxTextContainerOne = {
  position: "absolute",
  textAlign: "center",
  top: "11vh",
};

const sxTextContainerTwo = {
  position: "absolute",
  textAlign: "center",
  top: "45vh",
  left: 50,
};

const sxTextContainerThree = {
  position: "absolute",
  textAlign: "center",
  top: "45vh",
  right: 37,
};

const sxTextContainerFour = {
  position: "absolute",
  textAlign: "center",
  top: "82vh",
};

const sxTextWhite = {
  color: "white",
  fontFamily: "poppins",
  fontSize: 18,
  fontWeight: 400,
};

const sxTextPink = {
  color: "#e10064",
  fontFamily: "poppins",
  fontSize: 32,
  fontWeight: 400,
  marginTop: -2,
};

function Overlay() {
  const [showOverlay, setShowOverlay] = useState(true);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    showOverlay && (
      <div
        className="overlay"
        style={{
          display: "flex",
          width: "390px",
          height: "100%",
          background: "rgba(0, 0, 0, 0.7)",
          position: "fixed",

          justifyContent: "center",
          margin: "auto",
          zIndex: 10,
        }}
        onClick={handleCloseOverlay} // Schließt das Overlay bei Klick auf das Overlay selbst
      >
        <div style={sxTextContainerOne}>
          <h4 style={sxTextWhite}>Swipe hoch:</h4>
          <h3 style={sxTextPink}>Restart</h3>
        </div>

        <div style={sxTextContainerTwo}>
          <h4 style={sxTextWhite}>Swipe links:</h4>
          <h3 style={sxTextPink}>Nein!</h3>
        </div>

        <div style={sxTextContainerThree}>
          <h4 style={sxTextWhite}>Swipe rechts:</h4>
          <h3 style={sxTextPink}>Ja!</h3>
        </div>

        <div style={sxTextContainerFour}>
          <h4 style={sxTextWhite}>Swipe runter:</h4>
          <h3 style={sxTextPink}>Mehr Infos</h3>
        </div>

        <div style={sxStyleOverlay}>
          <div
            style={{
              width: 341.5,
              height: 0,
              left: 181,
              top: 6,
              position: "absolute",
              transform: "rotate(90deg)",
              transformOrigin: "0 0",
              border: "1px #E10064 dashed",
            }}
          ></div>
          <div
            style={{
              width: 380,
              height: 0,
              left: 362,
              top: 0,
              position: "absolute",
              transform: "rotate(-180deg)",
              transformOrigin: "0 0",
              border: "1px #E10064 dashed",
            }}
          ></div>
          <div
            style={{
              width: 380,
              height: 0,
              left: 362,
              top: 355,
              position: "absolute",
              transform: "rotate(-180deg)",
              transformOrigin: "0 0",
              border: "1px #E10064 dashed",
            }}
          ></div>
          ;
        </div>
      </div>
    )
  );
}

export default Overlay;
