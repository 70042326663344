// Checkbox

// importieren der Module
import * as React from "react";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

// Styling
const BpIcon = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItmes: "center",
  borderRadius: 3,
  width: 45,
  height: 45,
  border: "3px solid #636D7F",
  borderRadius: 10,
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#e10064",
  "&:before": {
    display: "block",
    width: 50,
    height: 50,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#e10064",
  },
});

const Background = {
  display: "block",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#272a33",
  width: "200px",
  height: "95px",
  borderRadius: "10px",
  position: "relative",
  marginBottom: 15,
  marginRight: 10,
};

const textStyle = {
  fontFamily: "poppins",
  color: "white",
  fontWeight: "normal",
  fontSize: "13px",
};
export default function CustomizedCheckbox({ checkboxChecked, onToggle }) {
  const handleToggle = () => {
    // Hier ändern wir den Status der Checkbox, wenn sie geklickt wird
    onToggle(!checkboxChecked);
  };

  return (
    <div style={Background} onClick={handleToggle}>
      <Checkbox
        disableRipple
        color="default"
        checked={checkboxChecked} // Wir setzen den Zustand der Checkbox
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
      />
      <h2 style={textStyle}>Zeig mir alles!</h2>
    </div>
  );
}
