import React from "react";
// import WebFont from "webfontloader";
import "../fonts/fonts.css";
import Menu from "../../src/components/menu/menu";

// WebFont.load({
//   google: {
//     families: ["Poppins: @100,200,300,400,500,600,700,800,900"],
//   },
// });

const sxStyleImpressum = {
  color: "white",
  fontFamily: "poppins",
  background: "#272a33",
  padding: 0,
  overflowX: "hidden",
  overflowY: "scroll",
  maxHeight: "100vh",
  width: "390px",
  textDecoration: "none",
};

const sxlinkStyle = {
  color: "#e10064",
  textDecoration: "none",
};

function Impressum() {
  return (
    <div style={sxStyleImpressum}>
      <div className="menu">
        <Menu />
      </div>
       <br></br>
      <br></br>
      <br></br>
      <div style={{padding: 20}}>
      <h1>Impressum</h1>
      <br></br>
      <br></br>

      <h2>Angaben gemäß § 5 TMG</h2>
      <br></br>

      <p>
        Matrixcode Academy GmbH
        <br />
        Falkenried 88
        <br />
        (Haus E)
        <br />
        20251 Hamburg
      </p>

      <p>
        Handelsregister: HRB 178988
        <br />
        Registergericht: Amtsgericht Hamburg
      </p>
      <br></br>

      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Andre Schütze
      </p>
      <br></br>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 175 10 50 586
        <br />
        E-Mail:{" "}
        <a href="mailto:info@matrixcode.academy" style={sxlinkStyle}>
          info@matrixcode.academy
        </a>
      </p>
      <br></br>

      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
        <br />
        DE358942722
      </p>
      <br></br>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          style={sxlinkStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      <br></br>

      <h2>
        Verbraucherstreitbeilegung/<br></br>Universalschlichtungsstelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p>
        Quelle:{" "}
        <a
          href="https://www.e-recht24.de/impressum-generator.html"
          style={sxlinkStyle}
        >
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
      </div>
    </div>
  );
}

export default Impressum;
