// vorletzter screen - 2 Gerichte auf einer page

// Importieren der Module
import React, { useState, useEffect } from "react";
import "../matchscreen/match.css";
import Card from "../../components/card/decisionCard";
import Navigator from "../../components/namiswan/navigator";
import Menu from "../../components/menu/menupink";

// Overlay
const sxStyleInfos = {
  width: "390px",
  color: "white",
  fontFamily: "poppins",
  marginTop: "0px",
  backgroundColor: "rgba(39, 42, 51, 1",
  borderRadius: "0px 0px 10px 10px",
  paddingTop: "70px",
  paddingBottom: "30px",
  paddingLeft: "30px",
  marginBottom: "5px",
  position: "absolute",
  zIndex: 3,
};
// overlay

function Decision() {
  const strapiUrl = "https://strapi.matchmeal.de";

  const [trash, setTrash] = useState([]);
  const [date, setDate] = useState([]);
  const [favourites, setFavourites] = useState([]);

  useEffect(() => {
    setFavourites(JSON.parse(localStorage.getItem("finalFavourites")));
  }, []);

  const InfoOverlay = ({ isOpen, onClose, children }) => {
    return (
      <>
        {isOpen ? (
          <div className="overlay">
            <div className="overlay__background" onClick={onClose}>
              <div className="overlay__container">
                <div className="overlay__controls">
                  <button
                    className="overlay__close"
                    type="button"
                    onClick={onClose}
                  ></button>
                </div>
                {children}
              </div>
            </div>
          </div>
        ) : null}
      </>
      
    );
  };
  function removeCard(cardId) {
    const updatedCardStack = favourites.filter((card) => card.id !== cardId);
    setFavourites(updatedCardStack);
    console.log("alle Cards", favourites);
  }

  // pushtoDate
  const pushToFinalFavourites = (card) => {
    date.push(card);
    setDate(card);
    localStorage.setItem("date", JSON.stringify(date));

    removeCard(card.id);
    console.log("pushtoDate", card);
  };

  const pushToTrash = (card) => {
    setTrash(card);
    removeCard(card.id);
    console.log("pushtoTrash", card);
  };

  if (date.length === 0) {
    return (
      <div>
        <div className="menu">
          <Menu />
        </div>
        <div
          style={{
            width: "390px",
            height: "400px",
            overflow: "hidden",
            display: "flex",
          }}
        >
          {favourites.map((card) => (
            <Card
              strapiUrl={strapiUrl}
              key={card.id}
              card={card}
              
              functions={{
                pushToTrash,
                InfoOverlay,
                pushToFinalFavourites,
              }}
            />
           
          ))}
        </div>
         <div
          style={{
            width: "390px",
            height: "400px",
            overflow: "hidden",
            display: "flex",
          }}
        >
         

         {/* Beschreibung */}
         
         
        </div>
       
      </div>
    );
  } else if (date.length === 0) {
    return (
      <Navigator
        text="Dir hat davon doch nichts gefallen? Starte die Suche erneut!"
        route={"/"}
      />
    );
  } else {
    return (
      <Navigator
        text={`Dein Date wartet auf Dich`}
        route="/date"
      />
    );
  }
}





export default Decision;

